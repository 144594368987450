







































































































































































































































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";
import ImageInput from "@/components/ImageInput.vue";
import util from "@/services/util";
import Base64FileInput from "@/components/Base64FileInput.vue";
import moment from "moment";

export default Vue.extend({
  components: { ImageInput, Base64FileInput },
  data() {
    return {
      util,
      userManager,
      dialog: false,
      form: false,
      dialogDelete: false,
      ruleRequired: value => !!value || this.$t("REQUIRED"),
      headers: [
        { text: "ID", value: "id" },
        { text: "Kinh doanh", value: "sale_user_name" },
        { text: "Giao dịch", value: "transaction_user_name" },
        { text: "Link", value: "url" },
        { text: "Yêu cầu", value: "requirement" },
        { text: "Tiến độ", value: "progress" },
        { text: "Chốt TT làm được", value: "commit" },
        { text: "Thông tin xưởng", value: "factory_info" },
        { text: "Đánh giá", value: "rating" },
        { text: "Ngày khởi tạo", value: "created_at" },
        { text: "Ngày hoàn thành", value: "approve_time" },
        { text: "Hình ảnh", value: "images" },
        !userManager.getImpersonateUserInfo() && {
          text: this.$t("ACTIONS"),
          value: "actions",
          sortable: false
        }
      ],
      option: "show",
      optionSearch: [
        { text: "Tất cả", value: "" },
        { text: "Hiện", value: "show" },
        { text: "Ẩn", value: "hidden" }
      ],
      options: {},
      search: "",
      loading: false,
      data: [],
      dataCount: 0,
      editedIndex: -1,
      editedItem: {
        images: []
      },
      defaultItem: {
        images: []
      },
      urlFunc(item) {
        return Vue.filter("discussionImageUrl")(item);
      },
      transactionUsers: [],
      saleUsers: [],
      ratings: [],
      filterProgress: "",
      filterSaleUserId: "",
      filterTransactionUserId: "",
      filterCreatedAt: "",
      availableProgressMap: {
        kt: "Khởi tạo",
        ckd: "Chờ kinh doanh",
        cgd: "Chờ giao dịch",
        ht: "Hoàn thành",
        t: "Trống"
      },
      availableRatingMap: {
        th: "Tặng hoa",
        nd: "Ném đá"
      },
      availableRatingIconMap: {
        th: "mdi-flower-poppy",
        nd: "mdi-wall"
      }
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Tạo tao đổi" : this.$t("EDIT");
    },
    availableProgresses() {
      const items = [];
      for (const k in this.availableProgressMap) {
        items.push({
          text: this.availableProgressMap[k],
          value: k
        });
      }
      return items;
    },
    availableRatings() {
      const items = [];
      for (const k in this.availableRatingMap) {
        items.push({
          text: this.availableRatingMap[k],
          value: k
        });
      }
      return items;
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true
    }
  },
  methods: {
    async initialize() {
      const filters = [];
      if (this.search) {
        filters.push({
          key: "id",
          operator: "=",
          value: this.search
        });
      }
      if (this.filterProgress) {
        filters.push({
          key: "progress",
          operator: "=",
          value: this.filterProgress
        });
      }
      if (this.filterSaleUserId) {
        filters.push({
          key: "sale_user_id",
          operator: "=",
          value: this.filterSaleUserId
        });
      }
      if (this.filterTransactionUserId) {
        filters.push({
          key: "transaction_user_id",
          operator: "=",
          value: this.filterTransactionUserId
        });
      }
      filters.push({
        key: "display_status",
        operator: "=",
        value: this.option
      });
      if (this.filterCreatedAt) {
        const m = moment(this.filterCreatedAt);
        filters.push({
          key: "created_at",
          operator: ">=",
          value: m.startOf("month").format("Y-MM-DD")
        });
        filters.push({
          key: "created_at",
          operator: "<=",
          value: m.endOf("month").format("Y-MM-DD")
        });
      }
      this.loading = true;
      const { items, count, ratings } = await apiClient.discussionGetAll({
        options: this.options,
        filters
      });
      this.data = items;
      this.dataCount = count;
      this.ratings = ratings;
      this.loading = false;
    },
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.closeDelete();
      await apiClient.packageDelete(this.editedItem);
      await this.initialize();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      let result;
      if (this.editedIndex > -1) {
        let fields = [];
        if (this.userManager.checkRole("kd", true)) {
          fields = ["url", "requirement", "transaction_user_id"];
        } else if (this.userManager.checkRole("gd", true)) {
          fields = ["commit", "factory_info"];
          if (this.editedItem.result_file) {
            fields.push("result_file");
          }
        }
        result = await apiClient.discussionUpdate(this.editedItem, fields);
      } else {
        result = await apiClient.discussionCreate(this.editedItem);
      }
      if (result) {
        this.close();
        await this.initialize();
      }
    },
    async approve(item) {
      if (
        await apiClient.discussionUpdate(
          {
            id: item.id,
            status: "Approved"
          },
          ["status"]
        )
      ) {
        await this.initialize();
      }
    },
    async hiddenRecord(item) {
      if (
        await apiClient.discussionSwitchRecord({
          id: item.id,
          display_status: "hidden"
        })
      ) {
        await this.initialize();
      }
    },
    async cancelApprove(item) {
      if (
        await apiClient.discussionUpdate(
          {
            id: item.id,
            status: "Pending"
          },
          ["status"]
        )
      ) {
        await this.initialize();
      }
    },
    async commit(item) {
      if (
        await apiClient.discussionUpdate(
          {
            id: item.id,
            commit: ""
          },
          ["commit"]
        )
      ) {
        await this.initialize();
      }
    },
    async setRating(item, value) {
      if (
        !confirm(
          `Xác nhận ${this.availableRatingMap[value]} cho giao dịch này?`
        )
      ) {
        return;
      }
      if (await apiClient.discussionSetRating(item.id, value)) {
        await this.initialize();
      }
    }
  },
  async created() {
    const transactionUsers = await apiClient.userGetAll({
      filters: [
        {
          key: "roles",
          operator: "equal_to",
          value: "gd"
        }
      ]
    });
    for (const user of transactionUsers.items) {
      this.transactionUsers.push({
        text: user.name,
        value: user.id
      });
    }
    const saleUsers = await apiClient.userGetAll({
      filters: [
        {
          key: "roles",
          operator: "equal_to",
          value: "kd"
        }
      ]
    });
    for (const user of saleUsers.items) {
      this.saleUsers.push({
        text: user.name,
        value: user.id
      });
    }
  }
});
